// extracted by mini-css-extract-plugin
export var cloudAppDevBenefitsSection = "P_h1";
export var cloudAppDevBusinessSection = "P_hW";
export var cloudAppDevCasesSection = "P_h3";
export var cloudAppDevIndustriesSection = "P_hX";
export var cloudAppDevModelSection = "P_hV";
export var cloudAppDevOurAchievementsSection = "P_h0";
export var cloudAppDevPrimeSection = "P_hS";
export var cloudAppDevProcessSection = "P_hY";
export var cloudAppDevProjLogoSection = "P_h2";
export var cloudAppDevQuotesSection = "P_h4";
export var cloudAppDevServicesSection = "P_hT";
export var cloudAppDevTechStackSection = "P_hZ";